import React from 'react'
import PropTypes from 'prop-types'

import { Link, graphql } from "gatsby"

const Tags = ({ pageContext, data }) => {
	const { tag } = pageContext
	const { edges, totalCount } = data.allMarkdownRemark
	const tagHeader = `${totalCount} post${
		totalCount === 1 ? "" : "s"
	} taggué avec "${tag}"`

	return (
		<div>
			<h1>{tagHeader}</h1>
			<ul>
				{edges.map(({node}) => {
					const { path } = node.frontmatter
					const { title } = node.frontmatter
					return (
						<li key={path}>
							<Link to={path}>{title}</Link>
						</li>
					)
				})}
			</ul>

			<Link to="/tags">Tous les tags</Link>
		</div>
	)

}

Tags.propTypes = {
	pageContext: PropTypes.shape({
		tag: PropTypes.string.isRequired
	}),
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			totalCount: PropTypes.number.isRequired,
			edges: PropTypes.arrayOf(
				PropTypes.shape({
					node: PropTypes.shape({
						frontmatter: PropTypes.shape({
							title: PropTypes.string.isRequired,
						}),
						fields: PropTypes.shape({
							slug: PropTypes.string.isRequired,
						}),
					}),
				}).isRequired
			),
		}),
	}),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
			path
            title
          }
        }
      }
    }
  }
`

